
footer {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, Sans-serif;
    background-color: #0d1117;
  }
  .footer {
    width: 100%;
    position: relative;
    height: 190px;
    background-color: #0d1117;
    color:#ffff
  }
  .footer a{
    text-decoration: none;
    color: inherit;
  }
  footer a:hover {
    text-decoration: underline;
  }
  .footer .footer-col {
    width: 190px;
    height: auto;
    float: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 0px 20px 20px 20px;
  }
  .footer .footer-col h1 {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 12px;
    line-height: 17px;
    padding: 20px 0px 5px 0px;
    footer-color: rgba(255,255,255,0.2);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.250em;
  }
  .footer .footer-col ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
  }
  .footer .footer-col ul li {
    footer-color: #999999;
    font-size: 14px;
    font-family: inherit;
    font-weight: bold;
    padding: 5px 0px 5px 0px;
    cursor: pointer;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
  }
  .social ul li {
    display: inline-block;
    padding-right: 5px !important;
  }
  
  .footer .footer-col ul li:hover {
    transition: .1s;
    -webkit-transition: .1s;
    -moz-transition: .1s;
  }
  .footer-col i, .footer .social-icons
  {
    font-size:35px;
  }
  .clearfix {
    clear: both;
  }
  .footer-bottom {
    padding: 15px 20px; 
    text-align: center  !important;
    font-size: 14px; 

  }
  .footer-bottom p{
    text-align: center  !important;

  }
  
  .footer-bottom-links {
    list-style: none;
    padding: 0; 
    margin: 10px 0 0; 
    display: inline-flex; 
    gap: 15px; 
  }
  
  
  @media only screen and (min-width: 1280px) {
    .footer-container {
      width: 95%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1139px) {
    .footer-container .social {
      width: 1000px;
      display: block;
    }
    .social h1 {
      margin: 0px;
    }
  }
  @media only screen and (max-width: 950px) {
    .footer .footer-col {
      width: 33%;
    }
    .footer .footer-col h1 {
      font-size: 14px;
    }
    .footer .footer-col ul li {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 768px) {
      .footer .footer-col {
        width: 100%;
      }
      .footer .footer-col h1 {
        font-size: 14px;
      }
      .footer .footer-col ul li {
        font-size: 13px;
      }
  }
  @media only screen and (max-width: 340px) {
    .footer .footer-col {
      width: 100%;
    }
  }