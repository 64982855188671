.tmd-container{
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-family: "Rubik", sans-serif;
}
.tmd h1{
    padding-top: 5rem;
    font-family: "Rubik", sans-serif;
}
.tmd{
    background: rgb(17, 20, 24);
    width: 100%;
    padding: 3rem 0;
    height: 100vh;
}
.tmd-container input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 50px;
    margin-top: 1rem;
    outline: none;
    height: 30px;

}
.tmd-des{
    color:#c7cad1;
    padding: 1rem 0;
}
.tmd-container button{
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: 'pointer';
}

.tmd-container  .error{
    color: red;
    margin-top: 10px;
}

.tmd-container  video{
   max-width: 100%;
    margin-bottom: 10px;
}
.tmd-container .card-container{
    margin: 20px 0;
}