.header{
    width: 100%;
    background: #202020;
}
.header_container {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  padding: 8px ;
}
.header_container .logo{
    width: 40px;
}
.header_container h1{
   font-size: 26px;
   color: #f5f5f5;
   padding-left: 20px;
   margin: 0;
   font-weight: 600;
   font-family: "Rubik", sans-serif;
}
.logo-container{
    display: flex;
    align-items: center;
}
.header ul li{
    list-style: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
}