.main-container {
  display: flex;
  width: 100%;
}
.ads-top {
  height: 100px;
}
.ads-left {
  width: 25%;
}
.ads-right {
  width: 15%;
}
.token h1 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-family: 'Rubik', sans-serif;
}
.token h2 {
  font-size: 1.15rem;
  line-height: 2.25rem;
  font-family: 'Rubik', sans-serif;
}
.token {
  width: 85%;
  margin: 0 auto;
}
.jwt-definition {
  padding-top: 2rem;
}
.jwt-decode {
  width: 100%;
}
.jwt-token {
  width: 50%;
  max-height: 90vh;
  min-height: 400px;
}
.header-token {
  color: #fb015b;
}
.payload {
  color: #d63aff;
}
.sign {
  color: #00b9f1;
}

.jwt-token .textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: 1px solid rgba(155, 155, 155, 0.5);
  border-radius: 4px;
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Rubik', sans-serif;
}
.jwt-output {
  width: 50%;
  min-height: 400px;
  max-height: 90vh;
  overflow-y: auto;
  border: 1px solid rgba(155, 155, 155, 0.5);
  border-radius: 4px;
  margin-left: 30px;
}

.jwt-header h4 {
  padding: 10px;
  border-top: 1px solid rgba(155, 155, 155, 0.5);
  border-bottom: 1px solid rgba(155, 155, 155, 0.5);
  margin: 0;
}

.jwt-header pre {
  padding: 16px;
}

.flex {
  display: flex;
}

.jwt-definition p {
  padding: 10px 0;
  font-size: 1rem;
  font-family: 'Rubik', sans-serif;
  line-height: 1.6;
}
