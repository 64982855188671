.jwt-banner{
    width: 100%;
    background: #000;
    text-align: center;
}
.jwt-container{
    background: #000;
    width: 90%;
    margin: 0 auto;
    padding: 150px 0 60px;


}
.banner-text{
    color: #fff;
}
.logo{
    color: #fff;
    font-weight: 800;
    font-size: 18px;
    padding-left: 20px;
}