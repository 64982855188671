.bc {
  display: flex;
  list-style: none;
  width: 90%;
  margin: 0;
  padding: 1rem 0;
  @media (max-width: 991px) {
    padding-left: 10px;
  }
}
.bc li {
  font-weight: 600;
  cursor: pointer;
}
.active {
  color: #b53836;
  font-weight: 600;
}
.bc-c {
  background-color: #fff;
}
